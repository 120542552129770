import React from "react";

function TextAnimation() {
  return (
    <div>
      <div className="bg-agate">
        <div className=" border-l-0 border-r-0 overflow-hidden px-2 py-[30px] relative ">
          <div className="flex top-1/2 transform -translate-y-1/2  absolute bg-agate text-[40px] text-flexi items-center gap-[250px]">
            <div className="flex w-[1440px] bg-agate gap-[100px] items-center logo ">
              <span>
                <p className="subheading whitespace-nowrap uppercase flex items-center ">
                  Full-body  WORKOUTS  combining, breathwork{" "}
                  <svg
                    className="inline-block mr-3 ml-1"
                    width="28"
                    height="30"
                    viewBox="0 0 28 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7234 27.6877C20.1981 27.6877 25.4469 21.9373 25.4469 14.8439C25.4469 7.7504 20.1981 2 13.7234 2C7.24877 2 2 7.7504 2 14.8439C2 21.9373 7.24877 27.6877 13.7234 27.6877Z"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                  </svg>
                  m
                  <svg
                    className="inline-block"
                    width="27"
                    height="28"
                    viewBox="0 0 27 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0312 0V27.7667"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M4.07129 23.6998L21.9943 4.06647"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M21.9943 23.6998L4.07129 4.06647"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M26.0619 13.885H0"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                  </svg>
                  vement and balance &nbsp;
                  <svg
                    className="inline-block ml-1 mr-2"
                    width="24"
                    height="27"
                    viewBox="0 0 24 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 17.8895H23.9758C23.9758 22.8253 18.6086 26.8249 11.9879 26.8249C5.36713 26.8249 0 22.8253 0 17.8895Z"
                      fill="#E0FEA8"
                    />
                    <path
                      d="M0 8.87903H23.9758C23.9758 13.8148 18.6086 17.8145 11.9879 17.8145C5.36713 17.8145 0 13.8148 0 8.87903Z"
                      fill="#E0FEA8"
                    />
                    <path
                      d="M0 0H23.9758C23.9758 4.93582 18.6086 8.93544 11.9879 8.93544C5.36713 8.93544 0 4.93582 0 0Z"
                      fill="#E0FEA8"
                    />
                  </svg>{" "}
                  to grow{" "}
                  <svg
                    className=" inline-block ml-1 mr-2"
                    width="37"
                    height="28"
                    viewBox="0 0 37 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1101_4522)">
                      <path
                        d="M33.9216 18.9351H11.7528C6.09556 18.9351 1.51172 15.0343 1.51172 10.2228C1.51172 5.41123 6.09789 1.51044 11.7528 1.51044H17.4286"
                        stroke="#E0FEA8"
                        strokeWidth="3.18477"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M26.6426 26.2925L34.1421 18.793L26.6426 11.2934"
                        stroke="#E0FEA8"
                        strokeWidth="3.18477"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1101_4522">
                        <rect width="36.2758" height="27.3613" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  the body & mind{" "}
                </p>
              </span>
            </div>
            <div className="flex w-[1440px] gap-[100px] items-center logo">
              <span>
                <p className="subheading whitespace-nowrap uppercase flex items-center gap-4">
                  Full-body WORKOUTS combining, breathwork{" "}
                  <svg
                    className="inline-block mr-3 ml-1"
                    width="28"
                    height="30"
                    viewBox="0 0 28 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7234 27.6877C20.1981 27.6877 25.4469 21.9373 25.4469 14.8439C25.4469 7.7504 20.1981 2 13.7234 2C7.24877 2 2 7.7504 2 14.8439C2 21.9373 7.24877 27.6877 13.7234 27.6877Z"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                  </svg>
                  m
                  <svg
                    className="inline-block"
                    width="27"
                    height="28"
                    viewBox="0 0 27 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.0312 0V27.7667"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M4.07129 23.6998L21.9943 4.06647"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M21.9943 23.6998L4.07129 4.06647"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M26.0619 13.885H0"
                      stroke="#E0FEA8"
                      strokeWidth="3.18306"
                      strokeMiterlimit="10"
                    />
                  </svg>
                  vement and balance{" "}
                  <svg
                    className="inline-block ml-1 mr-2"
                    width="24"
                    height="27"
                    viewBox="0 0 24 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 17.8895H23.9758C23.9758 22.8253 18.6086 26.8249 11.9879 26.8249C5.36713 26.8249 0 22.8253 0 17.8895Z"
                      fill="#E0FEA8"
                    />
                    <path
                      d="M0 8.87903H23.9758C23.9758 13.8148 18.6086 17.8145 11.9879 17.8145C5.36713 17.8145 0 13.8148 0 8.87903Z"
                      fill="#E0FEA8"
                    />
                    <path
                      d="M0 0H23.9758C23.9758 4.93582 18.6086 8.93544 11.9879 8.93544C5.36713 8.93544 0 4.93582 0 0Z"
                      fill="#E0FEA8"
                    />
                  </svg>{" "}
                  to grow{" "}
                  <svg
                    className=" inline-block ml-1 mr-2"
                    width="37"
                    height="28"
                    viewBox="0 0 37 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1101_4522)">
                      <path
                        d="M33.9216 18.9351H11.7528C6.09556 18.9351 1.51172 15.0343 1.51172 10.2228C1.51172 5.41123 6.09789 1.51044 11.7528 1.51044H17.4286"
                        stroke="#E0FEA8"
                        strokeWidth="3.18477"
                        strokeMiterlimit="10"
                      />
                      <path
                        d="M26.6426 26.2925L34.1421 18.793L26.6426 11.2934"
                        stroke="#E0FEA8"
                        strokeWidth="3.18477"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1101_4522">
                        <rect width="36.2758" height="27.3613" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  the body & mind{" "}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextAnimation;
