import React, { useState, useEffect } from "react";
import { createReactEditorJS } from "react-editor-js";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";

function LegalInfo(props) {
  const EditorJS = createReactEditorJS();
  const [EDITOR_JS_TOOLS, setTools] = useState<any>();

  const policy = props?.policy;
  const [current, setCurrent] = useState();
  const [expand, setExpand] = useState(false)

  useEffect(() => {
    const loadTools = async () => {
      const { EDITOR_JS_TOOLS } = await import("../../utils/Tools");
      setTools(EDITOR_JS_TOOLS);
    };
    loadTools();
  }, []);

  return (
    <div className="my-12 xl:my-[112px] details-tag-after  max-w-[750px] mx-6 lg:mx-auto">
      <div>
        <p className="heading text-center text-black">
          {props?.firstSection?.title ? props?.firstSection?.title : "Legal"}
        </p>
        <p className="body text-center text-black w-full max-w-[674px] mx-auto my-[54px]">
          {props?.firstSection?.desc
            ? props?.firstSection?.desc
            : `This section is designed to provide you with information on our
          website's terms and conditions, privacy policy, and other legal
          notices. By using our website, you agree to comply with these terms
          and policies. We take the protection of your privacy seriously, and
          our policies are designed to ensure that your personal information is
          kept safe and secure. If you have any questions or concerns about our
          legal policies, please feel free to contact us.`}
        </p>
      </div>
      <div className="details-tag-after max-w-[748px] mx-auto my-[64px] work-block">
        {policy && policy.map((i, idx) => (
          <div key={idx} className="border-b  border-b-lemonadeSecondary first:border-t first:border-t-lemonadeSecondary last:border-b last:border-b-lemonadeSecondary text-black rounded-[5px] py-6 pl-6 pr-6  outline-none relative">
            <div onClick={() => { setCurrent(idx); setExpand(!expand) }}>
              <svg className="absolute left-6" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"            >
                <path className={`${idx == current && expand ? "hidden" : ""}`} d="M9 1V17" stroke="#FFAA5C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 9H17" stroke="#FFAA5C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p className="subheading cursor-pointer !text-[20px] pl-12">{i.title}</p>
            </div>
            <div>
              {idx == current && expand && (
                <>
                  <EditorJS readOnly={true} tools={EDITOR_JS_TOOLS} defaultValue={i.content && JSON.parse(i.content)} />
                  <ButtonNavigate width="w-min mt-[44px] whitespace-nowrap" labelText="DOWNLOAD PDF" />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LegalInfo;
