import React from "react";

interface Iprops {
  img: string;
  title: string;
  bannerMobile: string;
}
function VideoContainer(props: Iprops) {
  const { img, title, bannerMobile } = props;

  return (
    <div className=" w-full mx-auto my-0 relative ">
      <div className="relative">
        {img?.slice(-3) == "mp4" ? (
          <video
            className=" w-[100vw] object-cover lg:h-[688px] h-[518px] overflow-hidden"
            autoPlay
            muted
            loop
            src={img}
          ></video>
        ) : (
          <>
            <img
              src={img}
              alt="banner"
              // className=" w-[100vw] object-bottom overflow-hidden object-cover"
              className=" w-[100vw] object-cover object-bottom md:block hidden  overflow-hidden"
            />
            <img
              src={bannerMobile}
              alt="banner"
              className=" w-[100vw] object-cover object-bottom md:hidden block overflow-hidden"
            />
          </>
        )}
        <p className="max-w-[820px] w-full text-center mx-auto heading text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
          {title}
        </p>
      </div>
    </div>
  );
}

export default VideoContainer;
